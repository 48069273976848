import {includes} from "lodash";
import {GameTypesWithoutLegacy, type GameTypeWithoutLegacy} from "./gameTypesLegacy";

// from libs/sport-shared/big9/types/betTypes.ts
export type Big9 = "BIG9";
export type Daily5 = "DAILY5";

/**
 * - scheduled: hasClosed: false, canHaveResults: false, hasPool: false, couponAllowed: false  |future race is scheduled but not yet open for betting
 * - bettable: hasClosed: false, canHaveResults: false, hasPool: true, couponAllowed: true
 * - upcoming: hasClosed: false, canHaveResults: false, hasPool: false, couponAllowed: false  |bet placement is closed race is about to start
 * - ongoing: hasClosed: true, canHaveResults: true, hasPool: true, couponAllowed: false
 * - results: hasClosed: true, canHaveResults: true, hasPool: true, couponAllowed: false
 * - cancelled: hasClosed: true, canHaveResults: false, hasPool: true, couponAllowed: false
 */
export type GameStatus =
    | "scheduled"
    | "upcoming"
    | "bettable"
    | "starting"
    | "ongoing"
    | "results"
    | "settled"
    | "cancelled";

export type GameSport = "trot" | "gallop" | string | undefined;

export type GameType =
    | "V86"
    | "V75"
    | "V65"
    | "V64"
    | "V5"
    | "V4"
    | "V3Legacy"
    | "V3"
    | "dd"
    | "ld"
    | "trio"
    | "tvilling"
    | "komb"
    | "vinnare"
    | "plats"
    | "vp"
    | "top7"
    | "GS75"
    | "raket";

export type VXYGameType =
    | "V75"
    | "V86"
    | "GS75"
    | "V65"
    | "V64"
    | "V5"
    | "V4"
    | "V3Legacy"
    | "V3";

export type DubbelGameType = "ld" | "dd";

export type ComboGameType = "dd" | "ld" | "trio" | "komb" | "tvilling";

export type NonComboGameType =
    | "V75"
    | "V86"
    | "V64"
    | "vinnare"
    | "top7"
    | "plats"
    | "vp";

export type SingleRaceGameType =
    | "trio"
    | "tvilling"
    | "komb"
    | "vinnare"
    | "plats"
    | "vp"
    | "top7";

export type DivisionGameType =
    | "V75"
    | "V86"
    | "GS75"
    | "V64"
    | "V65"
    | "V5"
    | "V4"
    | "V3Legacy"
    | "V3"
    | "dd"
    | "ld";

export type SharedBetGameType =
    | "V75"
    | "V86"
    | "GS75"
    | "V64"
    | "V65"
    | "V5"
    | "V4"
    | "dd"
    | "ld"
    | "trio"
    | "komb"
    | "tvilling"
    | "vinnare"
    | "plats"
    | "top7";

// TODO: consolidate below with atg-horse-game/domain/gameDefs.js?
export enum GameTypes {
    V75 = "V75",
    V86 = "V86",
    GS75 = "GS75",
    V64 = "V64",
    V65 = "V65",
    V5 = "V5",
    V4 = "V4",
    V3Legacy = "V3Legacy",
    V3 = "V3",
    dd = "dd",
    ld = "ld",
    trio = "trio",
    komb = "komb",
    tvilling = "tvilling",
    vinnare = "vinnare",
    plats = "plats",
    raket = "raket",
    vp = "vp",
    top7 = "top7",
}

export const {
    V75,
    V86,
    GS75,
    V64,
    V65,
    V5,
    V4,
    V3Legacy,
    V3,
    dd,
    ld,
    trio,
    komb,
    tvilling,
    vinnare,
    plats,
    raket,
    vp,
    top7,
} = GameTypes;

export const ALL_GAMES = [
    V75,
    V86,
    GS75,
    V64,
    V65,
    V5,
    V4,
    V3Legacy,
    V3,
    dd,
    ld,
    trio,
    komb,
    tvilling,
    vinnare,
    plats,
    raket,
    vp,
    top7,
];

export const VXY_GAMES = [V75, V86, GS75, V64, V65, V5, V4, V3Legacy, V3];

export const DIVISION_GAMES = [V75, V86, GS75, V64, V65, V5, V4, V3Legacy, V3, dd, ld];

export const COMBINATION_GAMES = [dd, ld, trio, komb, tvilling];

export const DOUBLE_GAMES = [dd, ld];

export const SINGLE_RACE_GAMES = [trio, tvilling, komb, vinnare, plats, vp, top7];

export const SHARED_BET_GAMES = [
    V75,
    V86,
    GS75,
    V64,
    V65,
    V5,
    V4,
    dd,
    ld,
    trio,
    komb,
    tvilling,
    top7,
];
export const gameTypePriorityOrder = {
    [V75]: 1,
    [V86]: 2,
    [GS75]: 3,
    [V64]: 4,
    [V65]: 5,
    [V5]: 6,
    [V4]: 7,
    [V3Legacy]: 8,
    [V3]: 8,
    [top7]: 9,
    [dd]: 10,
    [ld]: 11,
    [trio]: 12,
    [tvilling]: 13,
    [komb]: 14,
    [vinnare]: 15,
    [plats]: 16,
    [vp]: 17,
    [raket]: 18,
};

/**
 * Guard checking if string is a GameType
 */
export function isGameType(value?: string): value is GameTypeWithoutLegacy {
    return includes(ALL_GAMES, value);
}

export const isDivisionGameType = (gameType: GameType): gameType is DivisionGameType =>
    includes(DIVISION_GAMES, gameType);

export const isSingleRaceGameType = (
    gameType: GameType,
): gameType is SingleRaceGameType => includes(SINGLE_RACE_GAMES, gameType);

export const isCombinationGameType = (gameType: GameType): gameType is ComboGameType =>
    includes(COMBINATION_GAMES, gameType);

export const isDoubleGameType = (gameType: GameType): gameType is DubbelGameType =>
    includes(DOUBLE_GAMES, gameType);

export const isVXYGameType = (gameType: GameType): gameType is VXYGameType =>
    includes(VXY_GAMES, gameType);

export const isSharedBetGameType = (gameType: GameType): gameType is SharedBetGameType =>
    includes(SHARED_BET_GAMES, gameType);

export const getGameTypeFromString = (gameType?: string): GameType | undefined =>
    isGameType(gameType) ? GameTypes[gameType] : undefined;

export const getGameType = (gameType: string): GameTypeWithoutLegacy | undefined =>
    isGameType(gameType) ? GameTypesWithoutLegacy[gameType] : undefined;
