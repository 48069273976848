import type {LazyStore} from "@atg-shared/lazy-store";
import {runLazySaga} from "@atg-shared/lazy-store";
// @ts-expect-error Flow
import cmsSaga from "@atg-global-shared/legacy-cms/domain/cmsSaga";
import {highlightsSaga, highlightsPushListenerSaga} from "@atg-horse-shared/highlights";
import {userSettingsSaga} from "@atg-global-shared/user-settings";
import {hotGamesPushListenerSaga} from "@atg-horse-shared/hotgames";
import {fetchSaga} from "@atg-shared/fetch-redux";
import cleanupApolloCacheOnLogoutSaga from "./domain/cleanupApolloCacheOnLogoutSaga";

export default function rootSaga(store: LazyStore<any, any>) {
    runLazySaga(store, fetchSaga);
    runLazySaga(store, cmsSaga);
    runLazySaga(store, userSettingsSaga);
    runLazySaga(store, highlightsSaga);
    runLazySaga(store, highlightsPushListenerSaga, store.dispatch);
    runLazySaga(store, hotGamesPushListenerSaga, store.dispatch);
    runLazySaga(store, cleanupApolloCacheOnLogoutSaga);
}
