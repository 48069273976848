export const FAILED = {
    code: "failed",
    isLoading: false,
    error: {
        httpCode: 500,
        message: "Ett oförutsett tekniskt fel har inträffat.",
    },
};

type Status = {
    code?: string;
    message?: string;
    id?: string;
    context?: string;
    error?: {
        message: string;
    };
};

type LoadingError = {
    httpCode: number;
    message: string;
};

export type LoadingStatus = {
    code: "ok" | "loading" | "missing" | "failed";
    isLoading: boolean;
    error?: LoadingError;
};

export const OK = {
    code: "ok",
    isLoading: false,
};

export const isFailed = (status: Status) => status?.code === "failed";

export const isMissing = (status: Status) => status?.code === "missing";

export const isLoading = (status: {code: string}) => status && status.code === "loading";

// Fixme: very specific don't use it
export function statusToErrorMessage(status: Status) {
    switch (status.code) {
        case "loading":
            return null;
        case "ok":
            if (!status.message) return null;
            return {
                type: "info",
                id: status.id,
                text: status.message,
                context: status.context,
            };
        default:
            return {
                type: "danger",
                id: status.id,
                text: status.error?.message || "Tekniskt fel", // apparently this could fail without a null check.
                context: status.context,
            };
    }
}
