import {deprecated_defaultErrorTransform} from "@atg-shared/response-mapping/apiMessages";
import {
    SET_ACTIVE_HOT_GAMES_TAB,
    REQUEST_HOT_GAMES,
    RECEIVE_HOT_GAMES,
    START_LISTENING_TO_HOT_GAMES_PUSH,
    STOP_LISTENING_TO_HOT_GAMES_PUSH,
} from "./hotGamesActionTypes";
import * as HotGamesAPI from "./hotGamesAPI";

const keyRequired = (key: string) => {
    throw new Error(`${key} must be specified!`);
};

/**
 * @deprecated this is superseeded by `atg-fetch-redux`
 */
export default function deprecated_createFetchActions(options: any) {
    const {
        apiCall = keyRequired("ApiCall"),
        requestKey = keyRequired("requestKey"),
        receiveKey = keyRequired("receiveKey"),
        payloadTransform = (payload: any) => payload.data,
        errorTransform = deprecated_defaultErrorTransform,
        shouldFetch,
    } = options;

    const requestPayload = () => ({type: requestKey});

    const receivePayload = (payload: any) => ({
        type: receiveKey,
        payload: payloadTransform(payload),
    });

    const receivePayloadError = (error: Record<string, any>) => ({
        type: receiveKey,
        error: true,
        payload: errorTransform(error),
    });

    return function fetchPayload(params?: any) {
        return (dispatch: any, getState: any) => {
            if (shouldFetch && !shouldFetch(getState())) return null;

            dispatch(requestPayload());
            return apiCall(params).then(
                (response: any) => dispatch(receivePayload(response)),
                (error: Record<string, any>) =>
                    dispatch(receivePayloadError(error.response)),
            );
        };
    };
}

export const setActiveHotGamesTab = (tabIndex: number) => ({
    type: SET_ACTIVE_HOT_GAMES_TAB,
    payload: {
        activeTab: tabIndex,
    },
});

export const fetchHotGames = deprecated_createFetchActions({
    apiCall: HotGamesAPI.fetchHotGames,
    requestKey: REQUEST_HOT_GAMES,
    receiveKey: RECEIVE_HOT_GAMES,
    payloadTransform: (payload: any) => payload.data.hotGames,
});

export const receiveHotGamesPush = (data: {[key: string]: any}) => ({
    type: RECEIVE_HOT_GAMES,
    payload: data.hotGames,
});

export const startListeningToHotGamesPush = () => ({
    type: START_LISTENING_TO_HOT_GAMES_PUSH,
});

export const stopListeningToHotGamesPush = () => ({
    type: STOP_LISTENING_TO_HOT_GAMES_PUSH,
});
